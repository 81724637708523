/* eslint-disable max-lines */
import { variantProvider } from '../theme'
import { ButtonComposition, ButtonPresets } from '@codeleap/web'
import { shadeColor, assignTextStyle } from '@codeleap/common'

const createButtonStyle = variantProvider.createVariantFactory<ButtonComposition>()

export const AppButtonStyles = {
  ...ButtonPresets,
  default: createButtonStyle((theme) => ({
    wrapper: {
      cursor: 'pointer',
      border: 'none',
      // outline: 'none',
      ...theme.presets.row,
      ...theme.presets.relative,
      backgroundColor: theme.colors.neutral9,
      borderRadius: theme.borderRadius.rounded,
      transitionProperty: 'background, color, border, filter',
      transitionDuration: '0.2s',
      height: theme.values.itemHeight.default,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      width: 'auto',

      '&:hover': {
        backgroundColor: theme.colors.neutral7,
      },
      // '&:active': {
      //   backgroundColor: theme.colors.primary3,
      // },
    },
    text: {
      textAlign: 'center',
      color: theme.colors.neutral1,
      fontWeight: '500',
    },
    'text:disabled': {
      color: theme.colors.neutral4,
    },
    'loaderWrapper': {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    icon: {
      color: theme.colors.neutral1,
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    'icon:disabled': {
      color: theme.colors.neutral4,
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,

      cursor: 'not-allowed',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  large: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
    text: {
      fontWeight: '500',
    },
    'loaderWrapper': {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  small: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.small,
      ...theme.spacing.paddingHorizontal(1),
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '500',
    },
    loaderWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    leftIcon: {
      ...theme.spacing.marginLeft(1),
    },
    rightIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  floating: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  outline: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      fontWeight: '500',
      color: theme.colors.neutral9,
    },
    icon: {
      color: theme.colors.neutral10,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.neutral10,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  'primary:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
  })),
  minimal: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.transparent,

      '&:hover': {
        backgroundColor: shadeColor(theme.colors.neutral10, 0, 0.1),
      },
      '&:active': {
        backgroundColor: shadeColor(theme.colors.neutral10, 0, 0.1),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.transparent,

      '&:hover': {
        backgroundColor: theme.colors.transparent,
      },
      '&:active': {
        backgroundColor: theme.colors.transparent,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    'text:disabled': {
      color: theme.colors.neutral4,
    },
    icon: {
      color: theme.colors.primary3,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  destructive: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: theme.colors.destructive2,
        filter: 'brightness(90%)',
      },
      '&:active': {
        backgroundColor: theme.colors.destructive2,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
    loaderWrapper: {
      color: theme.colors.neutral1,
    },
  })),
  'destructive:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
  })),
  'destructive:minimal': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  destructive2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: shadeColor(theme.colors.destructive2, -30),
      },
    },
  })),
  list: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
      backgroundColor: theme.colors.neutral2,
      borderRadius: 0,
      borderTopStyle: 'solid',
      borderTopWidth: theme.values.borderWidth.small,
      borderTopColor: theme.colors.neutral3,
      ...theme.presets.justifyStart,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral3,
      },

      '&:first-of-type': {
        borderTopLeftRadius: theme.borderRadius.small,
        borderTopRightRadius: theme.borderRadius.small,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
        borderBottomWidth: 0,
      },
    },
    text: {
      fontWeight: '500',
      ...theme.spacing.marginLeft(1),
      color: theme.colors.neutral10,
      textAlign: 'left',
    },
    loaderWrapper: {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    leftIcon: {
      ...theme.spacing.marginLeft(1),
      marginRight: 0,
    },
    rightIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  'list:first': createButtonStyle((theme) => ({
    wrapper: {
      borderTopWidth: 0,
      borderTopRightRadius: theme.borderRadius.small,
      borderTopLeftRadius: theme.borderRadius.small,
    },
  })),
  'list:last': createButtonStyle((theme) => ({
    wrapper: {
      borderBottomRightRadius: theme.borderRadius.small,
      borderBottomLeftRadius: theme.borderRadius.small,
    },
  })),
  'list:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
      '&:active': {
        backgroundColor: theme.colors.primary4,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  noPadding: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
    },
  })),
  'text:capitalize': createButtonStyle((theme) => ({
    text: {
      textTransform: 'capitalize',
    },
  })),
  transparent: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: 'transparent',

      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
  })),
  tag: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.tiny,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      color: theme.colors.neutral9,
      ...assignTextStyle('p3')(theme).text,
    },
  })),
  'tag:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary1,
      borderRadius: theme.borderRadius.tiny,

      '&:hover': {
        backgroundColor: theme.colors.primary1,
      },
    },
    text: {
      color: theme.colors.neutral9,
      ...assignTextStyle('p3')(theme).text,
    },
  })),

  // Old variants
  circle: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
      ...theme.spacing.padding(1),
    },
  })),
  pill: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(0.5),
    },
  })),
  icon: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: 'transparent',
      aspectRatio: 1,
      display: 'flex',
      ...theme.presets.center,
      ...theme.spacing.padding(0),
    },
    text: {
      flex: 1,
      textAlign: 'center',
    },
    loaderWrapper: {
      ...theme.spacing.margin(0),
    },
    icon: {
      ...theme.spacing.margin(0),
      ...theme.presets.center,
      height: null,
      width: null,
      color: theme.colors.neutral1,
    },
    leftIcon: {
      ...theme.spacing.marginRight(0),
    },
    rightIcon: {
      ...theme.spacing.marginRight(0),
    },
  })),
  'reviews:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral9,

      '&:hover': {
        backgroundColor: theme.colors.neutral10,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  'reviews': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      color: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      color: theme.colors.neutral9,
      fontWeight: '500',
    },
  })),
  neutral9: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral9,

      '&:hover': {
        backgroundColor: theme.colors.neutral7,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  neutral10: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral10,

      '&:hover': {
        backgroundColor: theme.colors.neutral7,
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
  })),
  'text:neutral8': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral8,
    },
  })),
  'text:neutral9': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral9,
    },
  })),
  'icon:neutral8': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.neutral8,
    },
  })),
  'text:destructive2': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.destructive2,
    },
  })),
  'icon:destructive2': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.destructive2,
    },
    leftIcon: {
      color: theme.colors.destructive2,
    },
  })),
  'text:primary': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.primary3,
    },
  })),
  'text:neutral1': createButtonStyle((theme) => ({
    text: {
      color: theme.colors.neutral1,
    },
  })),
  positive2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.positive2,
    },
  })),
  select: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.justifySpaceBetween,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },

      '&:active': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    icon: {
      size: theme.values.iconSize[2],
      color: theme.colors.neutral7,
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  white: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
    },
  })),
  round: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  hideText: createButtonStyle((theme) => ({
    text: {
      display: 'none',
    },
  })),
  link: createButtonStyle((theme) => ({
    'wrapper': {
      textDecoration: 'none',
      color: theme.colors.neutral1,
      ...assignTextStyle('p1')(theme).text,
    },
    text: {
      textDecoration: 'none',
      color: theme.colors.neutral1,
      ...assignTextStyle('p1')(theme).text,
    },
  })),
  'link:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
      color: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
      '&:active': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  neutral2: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      color: theme.colors.neutral19,
    },
  })),
  'fileSelect:simplified': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral3(theme.values.borderWidth.small),
      color: theme.colors.neutral4,
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.fullWidth,
      height: theme.values.itemHeight.default,
    },
    text: {
      color: theme.colors.neutral4,
      marginRight: 'auto',
      ...theme.presets.alignSelfCenter,
    },
    icon: {
      size: theme.values.iconSize[2],
    },
    leftIcon: {
      color: theme.colors.neutral4,
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      color: theme.colors.neutral7,
    },
  })),
  'fileSelect:complete': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary1,
      ...theme.presets.fullWidth,
      height: 120,

      ...theme.presets.column,
      ...theme.presets.justifyCenter,
      ...theme.spacing.padding(1),
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.colors.primary1,
        ...theme.border.primary3(theme.values.borderWidth.small),
        '& > svg': {
          transform: 'scale(1.1)',
        },
      },
    },
    text: {
      ...theme.presets.p3,
    },
    icon: {
      size: theme.values.iconSize[4],
      color: theme.colors.primary3,
      margin: '0 auto',

    },
  })),
  tooltipMenu: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.colors.neutral4,
      },
      borderRadius: theme.borderRadius.small,
      ...theme.presets.justifyStart,
    },
    rightIcon: {
      ...theme.spacing.marginLeft('auto'),
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary2,
      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
    },
    text: {
      color: theme.colors.neutral10,
    },
    icon: {
      color: theme.colors.neutral10,
    },
  })),
  tooltipMenuLarge: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: 'transparent',
      height: theme.values.itemHeight.default,
      ...theme.presets.justifyStart,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    leftIcon: {
      height: theme.values.iconSize[2],
      width: theme.values.iconSize[2],
      color: theme.colors.neutral6,
    },
    text: {
      color: theme.colors.neutral6,
      ...assignTextStyle('p1')(theme).text,
    },
  })),
  'noUnderline': createButtonStyle(theme => ({
    text: {
      textDecoration: 'none',
    },
    'wrapper': {
      textDecoration: 'none',
    },
  })),
  option: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      ...theme.border.neutral2(theme.values.borderWidth.medium),
      ...theme.presets.justifySpaceBetween,
      borderRadius: theme.borderRadius.small,
      height: 'auto',
      minHeight: theme.values.itemHeight.default,
      ...theme.spacing.paddingVertical(1),

      '&:hover': {
        ...theme.border.neutral3(theme.values.borderWidth.medium),
        backgroundColor: theme.colors.neutral3,
      },
      '&:active': {
        ...theme.border.neutral3(theme.values.borderWidth.medium),
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      color: theme.colors.neutral6,
      textAlign: 'left',
      paddingRight: theme.values.iconSize[3],
    },
    icon: {
      color: theme.colors.neutral6,
      height: theme.values.iconSize[3],
      width: theme.values.iconSize[3],
    },
  })),
  'option:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary1,
      ...theme.border.primary3(theme.values.borderWidth.medium),

      '&:hover': {
        backgroundColor: theme.colors.primary1,
        ...theme.border.primary3(theme.values.borderWidth.medium),
      },
      '&:active': {
        backgroundColor: theme.colors.primary1,
        ...theme.border.primary3(theme.values.borderWidth.medium),
      },
    },
  })),
  'option:selectedWrong': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.alert1,
      ...theme.border.alert2(theme.values.borderWidth.medium),

      '&:hover': {
        backgroundColor: theme.colors.alert1,
        ...theme.border.alert2(theme.values.borderWidth.medium),
      },
      '&:active': {
        backgroundColor: theme.colors.alert1,
        ...theme.border.alert2(theme.values.borderWidth.medium),
      },
    },
  })),
  'option:neutral': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral3,
      ...theme.border.neutral6(theme.values.borderWidth.medium),

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
        ...theme.border.neutral6(theme.values.borderWidth.medium),
      },
      '&:active': {
        backgroundColor: theme.colors.neutral3,
        ...theme.border.neutral6(theme.values.borderWidth.medium),
      },
    },
  })),
  'normalize': createButtonStyle(theme => ({
    wrapper: {
      height: 'unset',
      padding: theme.spacing.value(0),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
  })),
  'story:readMore': createButtonStyle(theme => ({
    wrapper: {
      height: 'unset',
      paddingTop: theme.spacing.value(0.5),
      paddingBottom: theme.spacing.value(0.5),
      paddingLeft: theme.spacing.value(0),
      paddingRight: theme.spacing.value(0),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
      '&:active': {
        backgroundColor: theme.colors.neutral1,
      },
    },
  })),
  opaque: createButtonStyle(theme => ({
    wrapper: {
      backgroundColor: theme.colors.neutral10 + '1A',

      '&:hover': {
        backgroundColor: theme.colors.neutral10 + '1A',
      },
      '&:active': {
        backgroundColor: theme.colors.neutral10 + '1A',
      },
    },
  })),
  linkedIn: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.linkedIn,

      '&:hover': {
        backgroundColor: shadeColor(theme.colors.linkedIn, -10),
      },
      '&:active': {
        backgroundColor: theme.colors.linkedIn,
      },
    },
    text: {
      fontWeight: '500',
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
  })),
  socialMedia: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary1,
      borderRadius: theme.borderRadius.rounded,
      height: 40,

      [theme.media.down('small')]: {
        height: 26,
      },

      '&:hover': {
        backgroundColor: shadeColor(theme.colors.primary1, -10),
      },
    },
    text: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.primary4,

      [theme.media.down('small')]: {
        ...assignTextStyle('p2')(theme).text,
      },
    },
    icon: {
      color: theme.colors.primary4,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],

      [theme.media.down('small')]: {
        width: 14,
        height: 14,
      },
    },
  })),
}

import React from 'react'
import { AppForms, variantProvider } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { assignTextStyle, useForm, useI18N } from '@codeleap/common'
import { Button, Checkbox, Text, TextInput, View, AuthFormComponents, Link } from '@/components'
import { useKeydown } from '@/utils'
import { navigate } from 'gatsby'
import { useSearchParams } from '@codeleap/web'

type LoginFormProps = {
  isModal?: boolean
}

export const LoginForm = ({ isModal = true }: LoginFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.login)
  const form = useForm(AppForms.login, {})
  const { t } = useI18N()

  const [params] = useSearchParams({
    next: undefined,
  })

  const onLoginSuccess = () => {
    if (isModal) {
      AppStatus.setModal('login')
    } else {

      if (params.next) {
        navigate(params.next)
        return
      }
      navigate('/organisations/')
    }
  }

  const handleSocial = APIClient.Session.useSocialLogin((userSocial) => {
    if (userSocial) {
      onLoginSuccess()
    }
  }, 'login')

  const login = APIClient.Session.useLogin()

  const handleSubmit = async () => {
    try {
      const successLogin = await login({ ...form.values, email: form.values.email.toLowerCase() })

      if (successLogin) {
        onLoginSuccess()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  const toSignup = () => {
    if (isModal) {
      AppStatus.transitionModals(['login', 'signup'])
    } else {
      navigate('/auth/signup/')
    }
  }

  const toForgot = () => {
    if (isModal) {
      AppStatus.transitionModals(['login', 'forgotBefore'])
    } else {
      AppStatus.setModal('forgotBefore')
    }
  }

  useKeydown(() => {
    const isInvalidSubmit = isModal && !isFocused || !form.isValid
    if (isInvalidSubmit) return
    handleSubmit?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={t('Login.title')}
      />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'fullWidth', 'gap:1']}>
          <TextInput
            {...form.register('email')}
            leftIcon={{ name: 'mail' }}
          />
          <TextInput
            {...form.register('password')}
            leftIcon={{ name: 'key' }}
            visibilityToggle
          />
          <Checkbox
            {...form.register('remember')}
            onValueChange={(val) => {
              APIClient.Session.toggleRememberMe(val)
              form.setFieldValue('remember', val)
            }}
            variants={['left']}
          />
        </View>
        <View variants={['alignSelfCenter']}>
          <Text variants={['p3', 'color:neutral8']} >
            {t('Login.disclaimer')}
            <Link
              to='https://hurd.world/terms-of-use'
              text={t('termsOfUse') + ', '}
              style={styles.link}
              tabIndex={0}
            />
            <Link
              to='https://hurd.world/privacy-policy'
              text={t('privacyPolicy')}
              style={styles.link}
              tabIndex={0}
            />
            {t('Login.disclaimer2')}
            <Link
              to='https://hurd.world/community-guidelines'
              text={' ' + t('communityGuidelines')}
              style={styles.link}
              tabIndex={0}
            />
          </Text>
        </View>

        <AuthFormComponents.SocialProviders onPress={handleSocial} />

        <View variants={['column', 'gap:2', 'alignSelfCenter', isModal && 'fullWidth']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit Login`}
            text={t('Login.login')}
            variants={['marginHorizontal:auto', 'fullWidth', 'large', 'pill', !form.isValid && 'outline']}
            aria-label={t('Login.login')}
            aria-invalid={!form.isValid}
          />

          <Button
            onPress={toSignup}
            debugName={"Don't have account Button"}
            variants={['outline', 'pill', 'large', 'row', 'gap:1', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}
            aria-label={`${t('Login.txt1')} ${t('Login.txt2')}`}
          >
            <Text text={t('Login.txt1')} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
            <Text text={t('Login.txt2')} variants={['medium', 'color:primary3']} />
          </Button>

          <Button
            text={t('Login.forgot')}
            aria-label={t('Login.forgot')}
            onPress={toForgot}
            debugName={'ForgotPassword button'}
            variants={['minimal', 'large']}
          />
        </View>
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  link: {
    ...assignTextStyle('p3')(theme).text,
    color: theme.colors.primary3,
    fontWeight: '400',
    textDecoration: 'none',
  },
}), true)

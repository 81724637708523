import UAParser from 'ua-parser-js'

export const userAgentParser = new UAParser()

export const LocalStorageKeys = {
  SESSION_IS_DEV: '@Session.isDevelopment',
  THEME: '@Theme.colorScheme',
  DELETION_REQUEST: '@AccountDeletion',
  TESTER: '@CodeleapTester',
  SERVER: '@server',
  LOCALE: '@locale',
  AppleUserName: (user: string) => `@AppleUserName.${user}`,
  PERSIST_AUTH: '@auth.has_user',
  IN_REAUTHENTICATION: '@auth.in_reauthentication',
  QUESTIONS_LAST_AUTO_OPENENED: '@questions.last_auto_opened',
}

export const IS_SSR = typeof window === 'undefined'

import { createSlice } from '@codeleap/common'

export type SessionState = {
  firebaseUserClaims: {
    [key: string]: any
  }
}

const initialState: SessionState = {
  firebaseUserClaims: {},
}

export const sessionSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {
    setUserClaims: (state, firebaseUserClaims: SessionState['firebaseUserClaims']) => ({
      ...state,
      firebaseUserClaims,
    }),
  },
  asyncReducers: {},
})

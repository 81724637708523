import { api, Settings } from '@/app'
import { Replace, TypeGuards } from '@codeleap/common'
import { format, parse } from 'date-fns'
import { Organisation } from '@/types'
import { getFirebaseAuth } from '@/services/firebaseApp'
import { verifyAchievementProgress } from '../../../components/Achievements/Store'

export type Profile = {
  id: string
  avatar: any
  first_name: string
  last_name: string
  email: string
  password?: string
  birthday: string
  organization?: Partial<Organisation>
  gender: string
  country: string
  seniority: string
  department: string
  climate_priority: string
  inbound_source: string
  inbound_source_details: string
  display_name: string
  can_get_organization_involved: boolean
  email_verification_link?: string
  email_verified: boolean
}

export const ERRORS = {
  emailInUse: new Error('auth/email-already-in-use'),
  notRegistered: new Error('auth/not-registered'),
  reautenticationRequired: new Error('auth/requires-recent-login'),
}

const BASE_URL = 'profiles/'

const serializeBirthday = (birthday: Profile['birthday']) => {
  return TypeGuards.isString(birthday) ? birthday : format(birthday, 'dd/MM/yyyy')
}

const deserializeBirthday = (birthday: Profile['birthday']) => {
  return TypeGuards.isString(birthday) ? parse(birthday, 'yyyy-MM-dd', new Date()) : birthday
}

export async function create(data: Partial<Profile>) {
  const { avatar, ...profileData } = data

  const requestData = {
    data: {
      ...profileData,
    },
    files: null,
  }

  if (!!profileData.birthday) {
    requestData.data.birthday = serializeBirthday(profileData.birthday)
  }

  if (!!avatar && !TypeGuards.isString(avatar)) {
    requestData.files = avatar
  } else {
    requestData.files = 'null'
  }

  const response = await api.post<Profile>(`${BASE_URL}create/`, requestData,
    { multipart: true, debug: true },
  )

  return response.data
}

export async function isEmailAvailable(email: string) {

  const res = await api.get(`${BASE_URL}check_email/`, {
    params: {
      email,
    },
    validateStatus: () => true,
  })

  return res.status.toString().startsWith('2')

}

export async function update(profile: Replace<Partial<Profile>, 'organization', Organisation['id']>) {
  const firebaseAuth = await getFirebaseAuth()

  const { avatar, ...data } = profile

  const body: any = {
    data: {
      ...data,
    },
  }

  if (!!data.birthday) {
    body.data.birthday = serializeBirthday(data.birthday)
  }

  logger.debug('Update profile', body, 'Auth')

  const firebaseUser = firebaseAuth().currentUser

  const response = await api.patch<Profile>(`${BASE_URL}${firebaseUser.uid}/`, {
    data: body.data,
    files: !!avatar ? avatar?.file : null,
  }, {
    multipart: true,
    debug: true,
  })

  return response.data
}

export async function retrieve() {

  const response = await api.get<Profile>(`${BASE_URL}i/`)

  let birthday = null

  if (response.data.birthday) {
    birthday = deserializeBirthday(response.data.birthday)
  }
  return {
    ...response.data,
    birthday,
  }
}

export async function getBadges() {
  const response = await api.get<Badges>(`${BASE_URL}badges/`)

  return response.data
}

export async function requestPasswordReset(email: string) {
  const firebaseAuth = await getFirebaseAuth()

  const available = await isEmailAvailable(email)
  if (available) {
    alert({
      body: 'Could not find an account matching the specified email address',
      title: 'Unknown email',
    })
  }
  try {
    await firebaseAuth().sendPasswordResetEmail(email?.trim())
  } catch (e) {
    logger.error(e)
    alert({
      body: 'Error requesting reset',
      title: 'Please try again',
    })
  }

}

export async function deleteProfile(id: Profile['id']) {
  const response = await api.delete(`${BASE_URL}${id}/`)
  return response.data
}

export async function registerDevice(token: string) {
  const data = {
    registration_id: token,
    cloud_message_type: 'FCM',
    active: !!token,
  }

  logger.info('Register device', { data }, 'notifications')
  await api.post('notifications/device/', data)
}
type Option = { label: string; value: string }

export type ProfileOptions = {
  department: Option[]
  seniority: Option[]
  gender: Option[]
  climate_priority: Option[]
  country: Option[]
  inbound_source: (Option & { provide_details?: boolean })[]

}

export async function retrieveOptions() {
  const response = await api.get<ProfileOptions>(`${BASE_URL}options/`)

  return response.data
}

type LinkedInAccessTokenResponse = {
  access_token: string
}

const LinkedInCredentials = Settings.ApiCredentials.LinkedIn

let previousCode = null

export async function getLinkedInAccessToken(code: string) {
  if (previousCode === code) return

  const data = {
    grant_type: 'authorization_code',
    code: code,
    client_id: LinkedInCredentials.ClientID,
    client_secret: LinkedInCredentials.ClientSecret,
    redirect_uri: LinkedInCredentials.RedirectUri,
  }

  previousCode = code

  try {
    const response = await api.post<LinkedInAccessTokenResponse>(`${BASE_URL}linkedin/access_token/`, data)

    return response.data
  } catch (e) {
    logger.error(e)
    return null
  }
}

type LinkedinAuthResponse = {
  profile: {
    id: string
    localizedFirstName: string
    localizedLastName: string
    email: string
  }
  token: string
}

export async function linkedInAuth(accessToken: string) {
  const response = await api.get<LinkedinAuthResponse>(`${BASE_URL}linkedin/`, {
    params: {
      access_token: accessToken,
    },
    noAuth: true,
  })

  return response.data
}

export async function retrieveProfanityWords() {
  const response = await api.get<string[]>('adm/profanity_words/')

  return response.data
}

export async function sharedApp() {
  const response = await api.post(BASE_URL + 'shared_app/', {})

  const levelledUp = verifyAchievementProgress(response?.data?.achievement_progress)

  return levelledUp
}

export async function passwordReset(forEmail: string) {
  await api.get(BASE_URL + 'request_password_reset/', {
    params: {
      email: forEmail,
    },
  })
}

export async function requestEmailVerification() {
  await api.get(BASE_URL + 'request_email_verification/')
}
